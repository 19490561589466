





// document.addEventListener('DOMContentLoaded', function() {


// });




$(document).ready(function($) {




	console.log('So this is it then?');
	var 	overlay_info_open 		= document.querySelector('.item-name-info'),
			overlay_info 			= document.querySelector('.section-info-overlay'),
			blur_container 			= document.querySelector('.blur-container'),
			work_overlay_info		= document.querySelector('.work-info-overlay'),
			work_panel_less 		= document.querySelector('.work-info-overlay--less'),
			work_panel_more			= document.querySelector('.work-info-overlay--more');

	function toggleClass(elem, classname) {
		elem.classList.toggle(classname);
	};
	function toggleBlur() {
		blur_container.classList.toggle('is-blur');
	};
	document.querySelector('.item-name-info').addEventListener('click', function(event) {
		event.preventDefault();
		toggleBlur();
		toggleClass(overlay_info, 'is-open');
	});
	document.querySelector('.section-info-overlay-close').addEventListener('click', function(event) {
		event.preventDefault();
		toggleBlur();
		toggleClass(overlay_info, 'is-open');
	});

	document.querySelector('.work-info-overlay--more').addEventListener('click', function(event) {
		toggleBlur();
		toggleClass(work_overlay_info, 'is-open');
		toggleClass(this, 'button-state--active');
		toggleClass(work_panel_less, 'button-state--active');
	});
	document.querySelector('.work-info-overlay--less').addEventListener('click', function(event) {
		toggleBlur();
		toggleClass(work_overlay_info, 'is-open');
		toggleClass(this, 'button-state--active');
		toggleClass(work_panel_more, 'button-state--active');
	});
	document.querySelector('.work-info-overlay--outside').addEventListener('click', function(event) {
		toggleBlur();
		toggleClass(work_overlay_info, 'is-open');
		toggleClass(this, 'button-state--active');
		toggleClass(work_panel_more, 'button-state--active');
	});




    $(".royalSlider").css('display', 'block').royalSlider({
	    keyboardNavEnabled: true,
	    addActiveClass: true,
	    visibleNearby: {
	        enabled: true,
	        centerArea: 0.6,
	        center: true,
	        breakpoint: 650,
	        breakpointCenterArea: 0.74,
	        navigateByCenterClick: true
	    }
    });     




});




